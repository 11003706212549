/* Indian Food Section CSS */

#indian-food {
    margin-top: 8%;
  }
  
  #indian-food .title {
    text-align: center;
  }
  
  #indian-food .title .title-primary {
    color: var(--color-primary);
    font-family: var(--font-second);
    position: relative;
  }
  
  #indian-food .title .title-primary::before {
    content: "Food Package";
    position: absolute;
    top: 100%;
    left: 50%;
    right: -10%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    color: #000;
    opacity: 0.2;
    z-index: -1;
  }
  
  #indian-food .title .title-secondary {
    font-weight: 700;
    color: #000;
  }

  @media only screen and (max-width: 991px){
    #indian-food .title .title-primary::before{
      font-size: 50px;
    }
  }